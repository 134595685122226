var UC;
(function(UC){
  'use strict';
  UC.WEATHER_CODE_MAP = {
    200: '04',
    201: '04',
    202: '04',
    210: '04',
    211: '04',
    212: '04',
    221: '04',
    230: '04',
    231: '04',
    232: '04',
    300: '03',
    301: '03',
    302: '03',
    310: '03',
    311: '03',
    312: '03',
    313: '03',
    314: '03',
    321: '03',
    500: '03',
    501: '09',
    502: '09',
    503: '09',
    504: '09',
    511: '05',
    520: '09',
    521: '09',
    522: '09',
    531: '09',
    600: '16',
    601: '16',
    602: '16',
    611: '06',
    612: '06',
    615: '06',
    616: '06',
    620: '06',
    621: '06',
    622: '06',
    701: '18',
    711: '18',
    721: '18',
    731: '18',
    741: '18',
    751: '18',
    761: '18',
    762: '18',
    771: '20',
    781: '20',
    800: '00',
    801: '01',
    802: '01',
    803: '01',
    804: '02',
    900: '20',
    901: '20',
    902: '20',
    903: '16',
    904: '00',
    905: '20',
    906: '05',
  };
})(UC || (UC = {}));
var UC;
(function (UC) {
  "use strict";
  var CitySelect = (function () {
    function CitySelect(provinceNode, cityNode, cityList) {
      this.list = [];
      this.cid = null;
      this.province = null;
      this.city = null;
      var self = this;
      this.provinceNode = provinceNode;
      this.cityNode = cityNode;
      this.list = cityList;
      this.select1 = new UC.Select(this.provinceNode, {
        onChange: function (selectedNode, value) {
          self.setProvince(value);
          self.createCity();
          self.setSelectCity();
          selectedNode.parents("select").toggleClass("select-width");
        }
      });
      this.select2 = new UC.Select(this.cityNode, {
        onChange: function (selectedNode, value) {
          self.setCity(value);
          selectedNode.parents("select").toggleClass("select-width");
        }
      });
    }
    CitySelect.prototype.setList = function (list) {
      this.list = list;
    };
    CitySelect.prototype.getList = function () {
      return this.list;
    };
    CitySelect.prototype.setCid = function (cid) {
      this.cid = cid;
    };
    CitySelect.prototype.getCid = function () {
      return this.cid;
    };
    CitySelect.prototype.setProvince = function (province) {
      this.province = province;
    };
    CitySelect.prototype.getProvince = function () {
      return this.province;
    };
    CitySelect.prototype.setCity = function (city) {
      this.city = city;
    };
    CitySelect.prototype.getCity = function () {
      return this.city;
    };
    CitySelect.prototype.getCityData = function () {
      for (var i = 0; i < this.list.length; i++) {
        var data = this.list[i];
        if (data.name === this.province) {
          return data.children;
        }
      }
    };
    CitySelect.prototype.setSelectCity = function () {
      var option = this.cityNode.find("option").eq(0);
      option.prop("selected", true);
      this.setCity(option.val());
      this.setCid(option.attr("data-cid"));
    };
    CitySelect.prototype.createProvince = function () {
      var index = -1;
      var list = this.getList();
      UC.Select.create(this.provinceNode, list, CitySelect.tpl.provinceOption);
      for (var i = 0; i < list.length; i++) {
        if (list[i].name === this.province) {
          index = i;
          break;
        }
      }
      var target = this.provinceNode.find("option").eq(index);
      setTimeout(function () {
        target.prop("selected", true);
      }, 1);

    };
    CitySelect.prototype.createCity = function () {
      UC.Select.create(this.cityNode, this.getCityData(), CitySelect.tpl.cityOption);
    };
    CitySelect.prototype.setStorage = function () {
      UC.storage.item("cid_0", this.getCid());
      UC.storage.item("province_rs", this.getProvince());
      UC.storage.item("city_rs", this.getCity());
    };
    CitySelect.prototype.setSelected = function () {
      var names = [this.province, this.city];
      $.each([this.provinceNode, this.cityNode], function (index) {
        var options = $(this).find("option");
        options.each(function () {
          var option = $(this);
          if (option.text() === names[index]) {
            setTimeout(function () {
              option.attr("selected", true);
              return;
            }, 0);
          }
        });
      });
    };
    CitySelect.prototype.create = function (province, city) {
      this.setProvince(province);
      this.setCity(city);
      this.createProvince();
      this.createCity();
      this.setSelected();
    };
    CitySelect.tpl = {
      provinceOption: "<option value='{{name}}'>{{name}}</option>",
      cityOption: "<option value='{{name}}' data-cid='{{cid}}'>{{name}}</option>"
    };
    return CitySelect;
  })();
  UC.CitySelect = CitySelect;
})(UC || (UC = {}));
var UC;
(function (UC) {
  "use strict";
  var CitySetting = (function () {
    function CitySetting(element, options) {
      this.data = null;
      this.dialog = null;
      this.citySelect = null;
      this.onConfirm = options.onConfirm || function () {};
      this.element = element;
    }
    CitySetting.prototype.render = function (province, city, data) {
      var self = this;
      var temp = $("<div>");
      var node = $(CitySetting.tpl);
      temp.append(node);
      this.dialog = new UC.Dialog({
        title: "Замена город",
        container: this.element,
        content: temp.html(),
        relatedNode: this.element,
        offsetH: 0,
        axisY: 0,
        closeBtn: "<a class='dialog-btn-close' data-role='dialog-btn-cancel' href=''></a>",
        buttons: false,
        classname: "dialog city-select-dialog",
        mask: false,
        offsetY: "center",
        onConfirm: function () {
          var citySelect = self.citySelect;
          citySelect.setStorage();
          var getCid = citySelect.getCid(),
            cid = "",
            province = citySelect.getProvince(),
            city = citySelect.getCity();
          if (getCid) {
            cid = getCid;
          } else {
            cid = $("#J_citySelect").find("option:selected").attr("data-cid");
            province = $("#J_provinceSelect").find("option:selected").val();
            city = $("#J_citySelect").find("option:selected").val();
            UC.storage.item("cid_0", cid);
            UC.storage.item("province_rs", province);
            UC.storage.item("city_rs", city);
          }
          self.onConfirm(cid, city, province);
        }
      });
      this.dialog.open();
      var dialogContainer = this.dialog.dialogContainer;
      this.citySelect = new UC.CitySelect(dialogContainer.find("#J_provinceSelect"), dialogContainer.find("#J_citySelect"), data);
      this.citySelect.create(province, city, data);
    };
    CitySetting.prototype.load = function (target) {
      var self = this;
      var province = (UC.storage.isSupport && UC.storage.item("province_rs")) || target.attr("data-province");
      var city = (UC.storage.isSupport && UC.storage.item("city_rs")) || target.attr("data-city");
      if (!this.setting) {
        if (!UC.storage.item("weatherAllCity")) {
          $.ajax({
            url: CitySetting.url,
            type: "get",
            dataType: "jsonp",
            jsonpCallback: "weatherCity",
            success: function (response) {
              UC.storage.item("weatherAllCity", response);
              self.render(province, city, response);
              self.setting = true;
            }
          });
        } else {
          self.render(province, city, UC.storage.item("weatherAllCity"));
          self.setting = true;
        }
      } else {
        this.dialog.show();
      }
      $(document).off("click.dialog").on("click.dialog", function (event) {
        var currentTarget = $(event.target);
        if (self.dialog && self.dialog.dialogContainer) {
          if (currentTarget.closest(target).length || currentTarget.closest(self.dialog.dialogContainer).length) {
            return;
          }
          self.dialog.hide();
        }
      });
    };
    CitySetting.url = window.staticConfig.fullUrl + "js/weather/cities.min.js";
    CitySetting.tpl = [
      "<div class='city-select'>",
      "<select id='J_provinceSelect' class='city-select-item'></select>",
      "<select id='J_citySelect' class='city-select-item'></select>",
      "<a class='button button-primary dialog-btn-confirm' data-role='dialog-btn-confirm' href=''>Найти</a>",
      "</div>"
    ].join("");
    return CitySetting;
  })();
  UC.CitySetting = CitySetting;
})(UC || (UC = {}));
var UC;
(function(UC){
  UC.calender = {
    $pNode: $("#J_weather"),
    calenderUrl: window.weatherLink,
    calenderTpl: [
      '<div class="calender ellipsis">',
        '<a href="{{calenderUrl}}">',
          '<span>{{date}} {{month}} {{week}}</span>',
        '</a>',
      '</div>'
    ].join(''),
    monthList: ['Янв.', 'Фев.', 'Мрт.', 'Апр.', 'Май.', 'Июн.', 'Июл.', 'Авг.', 'Сен.', 'Окт.', 'Нбр.', 'Дек.'],
    dayList: ['Понедельник', 'Вторник', 'Среда', 'Чеверг', 'Пятница', 'Суббота', 'Воскресенье'],
    renderCalender: function(){
      var self = this;
      var d = new Date();
      self.$pNode.find(".calender").remove();
      self.$pNode.append(UC.Template.format(this.calenderTpl, {
        date: d.getDate(),
        month: self.monthList[d.getMonth()],
        week: self.dayList[d.getDay()-1],
        calenderUrl: self.calenderUrl
      }));
    }
  };
})(UC || (UC = {}));
var UC;
(function(UC){
  'use strict';
  UC.weather = {
    // $bgNode: $(".weather"),
    $pNode: $("#J_weather"),
    weatherUrl: window.weatherLink,
    cityTpl: [
      '<div class="weather-city" data-province="{{province}}" data-city="{{city}}">',
        '<i class="city-setting"></i>',
        '<div class="city-name ellipsis" >{{city}}</div>',
      '</div>'
    ].join(''),
    weatherTpl: [
      '<div class="weather-wrapper">',
        '<div class="weather-condition">',
          '<img title="{{desc}}" src="{{imgSrc}}"/>',
          '<a href="{{weatherUrl}}">',
            '<span class="weather-temper">{{temper}}°</span>',
          '</a>',
        '</div>',
        '<div class="weather-detail">',
          '<a href="{{weatherUrl}}">',
            '<span class="weather-range">{{high_temper}}° / {{low_temper}}°</span>',
            '<span title="{{desc}}" class="weather-desc ellipsis">{{desc}}</span>',
          '</a>',
        '</div>',
      '</div>'
    ].join(''),
    loadingTpl: '',
    getCid: function(){
      return UC.storage.item('cid_0') || '524901';
    },
    getCity: function(){
      return UC.storage.item('city_rs') || 'Москва';
    },
    getProvince: function(){
      return UC.storage.item('province_rs') || 'Москва и Московская область';
    },
    renderCity: function(city, province){
      var self = this;
      self.$pNode.find(".weather-city").remove();
      self.$pNode.append(UC.Template.format(self.cityTpl, {
        city: city,
        province: province
      }));
    },
    showLoading: function(){
      this.$pNode.parent().find('.weather-loading').show();
    },
    hideLoading: function(){
      this.$pNode.parent().find('.weather-loading').hide();
    },
    getWeatherData: function(){
      var self = this;

      self.$pNode.hide();
      self.showLoading();
      self.$pNode.find('.weather-wrapper').remove();
      $.ajax({
        type: 'GET',
        url: '//www.uc123.com/guide/russia_weather.php?cid=' + self.getCid(),
        // url: '//localhost:88/uc123-page/russia_weather.php',
        dataType: 'JSONP',
        success: function(response){
          if(response && response.code == 1){
            var data = response.data;
            var code = data.weather;
            var imgIndex = UC.WEATHER_CODE_MAP[code] || '20';
            self.$pNode.append(UC.Template.format(self.weatherTpl, $.extend({}, data, {
              imgSrc: window.staticConfig.fullUrl + 'img/weather/' + imgIndex + '.png',
              weatherUrl: self.weatherUrl
            })));
            self.renderCity(self.getCity(), self.getProvince());
            UC.calender.renderCalender();
            self.hideLoading();
            self.$pNode.fadeIn('fast');
          } else {
            this.error();
          }
        },
        error: function(){
          self.log('Request Failure');
// TODO 错误处理
        }
      });
    },
    log: function(ckName) {
      UC.logWrite.write("click", {
        "ck_rg": "Weather",
        "ck_name": ckName
      });
    },
    init: function(){
      var self = this;
      var cid = self.getCid();
      self.getWeatherData(cid);
      var weather = self.$pNode;
      var citySetting = new UC.CitySetting($("#J_weather"), {
        onConfirm: function (cid, city, province) {
          self.getWeatherData(cid);
          self.log('Confirm City');
        }
      });
      function citySettingEvent() {
        /*jshint validthis:true */
        var target = $(this);
        citySetting.load(target);
      }
      weather.on('click', '.weather-city', citySettingEvent);
      weather.on('click', '.weather-city', function(){
        self.log('Select City');
      });
      weather.on('click', '.weather-temper, .weather-detail, .calender', function(){
        self.log('View Weather Page');
      });
    }
  };
  UC.weather.init();
})(UC || (UC={}));
var UC;
(function (UC) {
    'use strict';
    var Mask = (function () {
        function Mask(container) {
            this.id = '__mask__' + new Date().getTime();
            this.container = container || $(document);
            this.body = $(document.body);
            this.maskContainer = null;
        }
        Mask.prototype.setTemplate = function (template) {
            if (template && typeof template !== 'string') {
                template = '<div id="' + this.id + '" class="mask"></div>';
            }
            this.template = template;
        };
        Mask.prototype.getTemplate = function () {
            return this.template;
        };
        Mask.prototype.run = function () {
            var size = UC.Size.get(this.container);
            this.body.append($(this.template).attr('id', this.id));
            this.maskContainer = $('#' + this.id);
            this.maskContainer.css({
                zIndex: 99,
                position: 'absolute',
                top: 0,
                left: 0,
                width: size.width,
                height: size.height,
                background: '#000',
                opacity: '0'
            });
            this.show();
        };
        Mask.prototype.destroy = function () {
            this.id = null;
            this.maskContainer.remove();
            this.maskContainer = null;
        };
        Mask.prototype.show = function () {
            this.maskContainer.css('opacity', '.5');
        };
        Mask.prototype.hide = function () {
            this.maskContainer.hide();
        };
        return Mask;
    })();
    UC.Mask = Mask;
})(UC || (UC = {}));
var UC;
(function (UC) {
    'use strict';
    var Dialog = (function () {
        function Dialog(config) {
            this.isOpen = false;
            this.instanceMask = null;
            this.dialogContainer = null;
            this._id = 'dialog_' + new Date().getTime();
            this.classname = config.classname || 'dialog';
            this.config = $.extend(true, {}, Dialog.defaultConfig, config);
            this.container = this.config.container;
            this.confirm = this.config.onConfirm || function () {};
            this.cancel = this.config.onCancel || function () {};
            this.onOpen = this.config.onOpen || function () {};
            this.body = $(document.body);
            if (this.config.mask) {
                this.instanceMask = new UC.Mask();
                this.instanceMask.setTemplate(this.config.mask);
            }
            Dialog.instance.push(this);
        }
        Dialog.prototype.open = function () {
            if (!this.isOpen) {
                this.render();
                this.onOpen();
            }
            this.isOpen = true;
        };
        Dialog.prototype.setContent = function (content) {
            this.dialogContainer.find('dialog-content').html(content);
        };
        Dialog.prototype.render = function () {
            var self = this;
            var config = this.config;
            var tpl = config.tpl;
            var data = {
                id: this._id,
                classname: this.classname,
                title: config.title,
                content: config.content,
                buttons: config.buttons,
                closeBtn: config.closeBtn
            };
            this.instanceMask && this.instanceMask.run();
            self.body.append(UC.Template.format(tpl, data));
            self.dialogContainer = $('#' + self._id);
            self.setPosition();
            self.bind();
        };
        Dialog.prototype.bind = function () {
            this.dialogContainer.on('click', '[data-role=dialog-btn-close]', $.proxy(this.close, this));
            this.dialogContainer.on('click', '[data-role=dialog-btn-confirm]', $.proxy(this.clickconfirm, this));
            this.dialogContainer.on('click', '[data-role=dialog-btn-cancel]', $.proxy(this.clickcancel, this));
        };
        Dialog.prototype.clickconfirm = function (event) {
            event.preventDefault();
            this.confirm();
            this.hide();
        };
        Dialog.prototype.resize = function () {
            this.setPosition();
        };
        Dialog.prototype.clickcancel = function (event) {
            event.preventDefault();
            this.hide();
        };
        Dialog.prototype.destroy = function () {
            this._id = null;
            this.isOpen = false;
            this.dialogContainer.remove();
            this.instanceMask.destroy();
        };
        Dialog.prototype.setPosition = function () {
            var config = this.config;
            var relatedNode = config.relatedNode;
            var size = UC.Size.get(this.container);
            var width = config.width;
            var height = config.height;
            var top = size.top;
            var left = size.left;
            var offsetH = config.offsetH || 0;
            var axisY = config.axisY || 0;
            if (relatedNode) {
                size = UC.Size.get(relatedNode);
                width = size.width;
                height = size.height;
                top = size.top;
                left = size.left;
            }
            var offsetY = config.offsetY;
            this.dialogContainer.css({
                width: width
            });
            switch (offsetY) {
            case 'bottom':
                top = (size.top + size.height) - height;
                break;
            case 'center':
                top = (size.height - height) / 2 + size.top;
                left = (size.width - width) / 2 + size.left;
            }
            this.dialogContainer.css({
                zIndex: 100,
                position: 'absolute',
                top: top + axisY,
                left: left,
                width: width,
                height: height - offsetH
            });
        };
        Dialog.prototype.close = function (event) {
            event.preventDefault();
            if (this.isOpen) {
                this.destroy();
            }
        };
        Dialog.prototype.show = function () {
            if (!this.isOpen) {
                this.instanceMask && this.instanceMask.show();
                this.dialogContainer.show();
                this.isOpen = true;
            }
            this.setPosition();
        };
        Dialog.prototype.hide = function () {
            this.instanceMask && this.instanceMask.hide();
            this.dialogContainer.hide();
            this.isOpen = false;
        };
        Dialog.resize = function () {
            window.onresize = function () {
                for (var i = 0; i < Dialog.instance.length; i++) {
                    var instance = Dialog.instance[i];
                    instance.resize();
                }
            };
        };
        Dialog.instance = [];
        Dialog.defaultConfig = {
            container: $(document.body),
            width: 500,
            height: 300,
            content: '',
            mask: true,
            buttons: '<a class="button button-primary button-large dialog-btn-confirm" data-role="dialog-btn-confirm" href="">确认</a> <a class="button button-large dialog-btn-cancel" data-role="dialog-btn-cancel" href="">取消</a>',
            closeBtn: '<a class="dialog-btn-close" data-role="dialog-btn-close" href=""></a>',
            tpl: [
                '<div id="{{id}}" class="{{classname}}">',
                '{{closeBtn}}',
                '<div class="dialog-head">',
                '<h2 class="title">{{title}}</h2>',
                '</div>',
                '<div class="dialog-content">',
                '{{content}}',
                '</div>',
                '<div class="dialog-footer">',
                '{{buttons}}',
                '</div>',
                '</div>'
            ].join('')
        };
        return Dialog;
    })();
    UC.Dialog = Dialog;
    Dialog.resize();
})(UC || (UC = {}));
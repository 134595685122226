var UC;
(function(UC){
  UC.mailList = {
    list: $("#J_mailList"),
    lsKey: "selectedMail",
    itemTemplate: [
        "<a class='mail-item' title='{{name}}' href='{{url}}'>",
        "<img src='{{imgUrl}}'/>",
        "</a>"
      ].join(""),
    init: function(data){
      var self = this;
      $(".mail-text,.mail-edit").on("click", function(){
        if(self.list.children().length){
          self.toggleList();
        } else {
          self.generateList(data);
          self.showList();
        }
      });
      $(document).bind("click", function(e){
        var elem = $(e.target || e.srcElement);
        if (!elem.closest(".mail-selector").length) {
          if(!self.list.hasClass("hidden-element")){
            self.hideList();
          }
        }
      });
    },
    generateList: function(data){
      var listHtml = "";
      for(var i=0, len=data.length;i<len;i++){
        var item=data[i];
        listHtml += ("<li>" + UC.Template.format(this.itemTemplate, {
          name: item.name,
          url: item.url,
          imgUrl: item.imgUrl
        }) + "</li>");
      }
      $(".mail-list").append(listHtml);
      var self = this;
      $(".mail-item").on("click", function(){
        if ($(this).hasClass("selected")!==true) {
          self.selectListItem($(this));
        }
      });
    },
    setDefaultItem: function(item){
      var wrapper = $(".mail-selector").find(".mail-content-wrapper");
      var itemCopy = item.clone();
      wrapper.find(".mail-text,.selected").remove();
      wrapper.append(itemCopy);
      wrapper.find(".mail-item").addClass("selected");
      wrapper.find(".mail-edit").addClass("to-left");
    },
    selectListItem: function(item){
      var data = {
        name: item.attr("title"),
        url: item.attr("href"),
        imgUrl: item.children("img").attr("src")
      };
      UC.logWrite.write("click", {
        ck_rg: "choose email",
        ck_name: data.name
      });
      this.setDefaultItem(item);
      this.hideList();
      var temp = ls && ls.item(this.lsKey, JSON.stringify(data));
      // window.open(item.attr("href"));
    },
    toggleList: function(){
      if (this.list.hasClass("hidden-element")) {
        this.showList();
      } else {
        this.hideList();
      }
    },
    showList: function(){
      this.list.removeClass("hidden-element");
    },
    hideList: function(){
      this.list.addClass("hidden-element");
    }

  };
})(UC || (UC={}));
var mailData = [{
    name: "Mail.Ru",
    url: "http://mail.ru",
    imgUrl: "//gtd.alicdn.com/tfscom/TB1DVU4KFXXXXboXXXXXXXXXXXX"
  },{
    name: "YandexMail",
    url: "http://mail.yandex.ru",
    imgUrl: "//gtd.alicdn.com/tfscom/TB1mEIDKFXXXXcqXVXXXXXXXXXX"
  },{
    name: "Gmail",
    url: "http://accounts.google.com/ServiceLogin?service=mail&continue=https://mail.google.com/mail/&hl=ru#identifier",
    imgUrl: "//gtd.alicdn.com/tfscom/TB13o2HKpXXXXX4aXXXwu0bFXXX"
  }];
UC.mailList.init(mailData);

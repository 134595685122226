var UC;
(function(UC){
  UC.middleNewsVideo = {
    wrapperTpl: "<div class='mid-content' mod='{{moduleName}}' id='J_{{dataId}}' style='display:none'>{{contentHtml}}</div>",
    apiInfo: {
      "newsContent1":{
        url:"//wow.ucweb.com/wow/config/2/rusheadlinesnews.json",
        callback: "uc123RussiaHeadlinesNews"
      },
      "newsContent2":{
        url:"//wow.ucweb.com/wow/config/2/ruspoliticsnews.json",
        callback: "uc123RussiaPoliticsNews"
      },
      "newsContent3":{
        url:"//wow.ucweb.com/wow/config/2/rustechnews.json",
        callback: "uc123RussiaTechNews"
      },
      "newsContent4":{
        url:"//wow.ucweb.com/wow/config/2/ruseconomicsnews.json",
        callback: "uc123RussiaEconomicsNews"
      },
      "newsContent5":{
        url:"//wow.ucweb.com/wow/config/2/russocietynews.json",
        callback: "uc123RussiaSocietyNews"
      },
      "newsContent6":{
        url:"//wow.ucweb.com/wow/config/2/russportnews.json",
        callback: "uc123RussiaSportNews"
      },
      "video1Content":{
        url:"//wow.ucweb.com/wow/config/2/rusmovies.json",
        callback: "uc123RussiaMovies"
      },
      "video2Content":{
        url:"//wow.ucweb.com/wow/config/2/rusvideos.json",
        callback: "uc123RussiaVideos"
      }
    },
    moduleInfo: {
      news: {
        num: 5,
        tpl: [
          // "<div class='news-content'>",
          "<a href='{{originalUrl}}' title='{{title}}' class='news-content'>",
          "<span class='news-title ellipsis'>{{title}}</span>",
          "<p><span class='news-view-num'><i></i>{{virtualViewNumber}}</span><span class='news-time'>{{timeText}}</span></p>",
          "</a>",
          // "</div>"
        ].join("")
      },
      "video1": {
        num: 4,
        tpl: [
          // "<div class='video1-content'>",
          "<a href='{{playSrc}}' title='{{title}}' class='video1-content'>",
          "<img src='{{coverUrl}}'/>",
          "<div class='video-text-wrapper'><span class='ellipsis'>{{title}}</span></div>",
          "<span class='video-tag'>{{rating}}</span>",
          "</a>",
          // "</div>"
        ].join("")
      },
      "video2": {
        num: 3,
        tpl: [
          // "<div class='video2-content'>",
          "<a href='{{playSrc}}' title='{{title}}' class='video2-content'>",
          "<img src='{{image}}'/>",
          "<div class='video-text-wrapper'><span class='ellipsis'>{{title}}</span></div>",
          "<span class='video-tag'>{{duration}}</span>",
          "</a>",
          // "</div>"
        ].join("")
      }
    },
    currentTab: "newsContent1",
    lsTimeKey: "ruLastUpdated",
    lsDataKey: "ruOriginalData",
    data: {},
    pageId: {},
    updatedTime: {},
    originalData: window.presetData,
    firstScreenData: ["newsContent1", "video1Content", "video2Content"],
    errorPage:"<div class='error-page'><p>Загрузка не удалась, попробуйте обновить, или повторите позже, пожалуста!</p><span class='error-refresh-btn'>обновите</span></div>",
    logRgData: {
      "newsContent1":"News-Главные",
      "newsContent2":"News-Политика",
      "newsContent3":"News-Технологии",
      "newsContent4":"News-Экономика",
      "newsContent5":"News-Общество",
      "newsContent6":"News-Спорт",
      "video1Content":"Video-Long",
      "video2Content":"Video-Short",
      "news": "News",
      "video1": "Video-Long",
      "video2": "Video-Short"
    },
    getData: function(dataId){
      var self = this;
      if (typeof(dataId) !== 'string') {
        return;
      }
      $.ajax({
        dataType: "JSONP",
        jsonpCallback: self.apiInfo[dataId].callback,
        // async: false,
        type: "GET",
        url: self.apiInfo[dataId].url,
        success:function(response){
          if (response && response.code == 1) {
            self.data[dataId] = self.responseDataProcess(response.data);
            self.pageId[dataId] = 1;
            // 页面渲染
            var cacheData = self.renderPage(dataId);
            // 记录更新时间
            self.updatedTime[dataId] = Date.parse(new Date());
            // ls && ls.item(self.lsTimeKey, JSON.stringify(self.updatedTime));
            // 刷新缓存，只更新首屏数据的缓存
            if(self.firstScreenData.indexOf(dataId) !== -1){
              self.originalData[dataId] = cacheData;
              var temp = ls && ls.item(self.lsDataKey, JSON.stringify(self.originalData));
            }
          } else {
            this.error();
          }
        },
        error: function(){
          self.pageId[dataId] = 0;
          self.renderPage(dataId);
        }
      });
    },
    responseDataProcess: function(rawData){
      if (rawData.movie) {  // 拼接电影电视数据
        var data = [];
        var movieData = rawData.movie;
        var serialData = rawData.serial;
        for(var i=0; i < rawData.movie.length-1 && i < rawData.serial.length-1; i+=2) {
          data.push(movieData[i]);
          data.push(movieData[i+1]);
          data.push(serialData[i]);
          data.push(serialData[i+1]);
        }
        for (i = data.length - 1; i >= 0; i--) {  // 检查评分数据
          var rating = data[i].rating;
          data[i].rating = (rating!==null && rating>0)? parseFloat(rating).toFixed(1): "Нет";
        }
        return data;
      }
      return rawData;
    },
    getTimeText: function(publishTime) {
      var addZero = function(num) {
        return (num<10)? "0"+num:""+num;
      };
      var timeInterval = {
        _1min: 60000,
        _1hour: 3600000
      };
      var cTime = new Date();
      var pTime = new Date(publishTime);
      var delta = Date.parse(cTime) - publishTime;
      if (delta<timeInterval._1min) {
        return "только"; // 刚刚
      } else if(delta<timeInterval._1hour){
        return Math.floor(delta/60000) + " мин. назад"; // xx分钟前
      } else if(delta<timeInterval._1hour*8){
        return Math.floor(delta/3600000) + " ч. назад"; // xx小时前
      } else if(pTime.getDate()==cTime.getDate() && delta<timeInterval._1hour*24) {
        return "Сегодня " + addZero(pTime.getHours())+":"+addZero(pTime.getMinutes());  // 今天
      } else if(pTime.getDate()==cTime.getDate()-1 && delta<timeInterval._1hour*48){
        return "Вчера " + addZero(pTime.getHours())+":"+addZero(pTime.getMinutes());  // 昨天
      } else {
        return addZero(pTime.getDate())+"."+addZero(pTime.getMonth()+1)+"."+pTime.getFullYear();  // 日期
      }
    },
    renderPage: function(dataId){
      var self = this;
      var module = self.getModuleName(dataId);
      var info = self.moduleInfo[module];
      var $parent = $("."+module+"-wrapper");
      var data;
      if (self.data[dataId] && self.pageId[dataId] !== 0) {
        data = self.data[dataId].slice(0, info.num);
      } else {
        data = null;
      }

      if(self.firstLoad === true && data === null) { // 首屏加载失败，展现预置数据
        data = self.originalData[dataId];
        self.data[dataId] = data;
      }
      var html = self.getContentPage(data, info.tpl, dataId);
      self.feedPage(html, dataId, $parent);
      if (self.firstLoad !== true && data === null) { // 非首屏加载失败，绑定错误刷新按钮
        self.bindErrorBtn();
      }
      return data;
    },
    getDataId: function(module) {
      var dataId;
      switch(module) {
        case "news":
          dataId = this.currentTab;
          break;
        case "video1":
          dataId = "video1Content";
          break;
        case "video2":
          dataId = "video2Content";
          break;
        default:
          return;
      }
      return dataId;
    },
    // 加载页面时使用；换页时使用
    getContentPage: function(data, tpl, dataId) {
      var self = this;
      var html = self.wrapperTpl;
      var moduleName = self.getModuleName(dataId);
      if(!data) { // 数据为空时返回错误页面
        return UC.Template.format(html, {
          moduleName: moduleName,
          dataId: dataId,
          contentHtml: self.errorPage
        });
      }
      var contentHtml = "";
      $.each(data, function(i, each) {
        var imgFlag = true;
        var innerTpl = tpl;
        for (var prop in each) {
          if(!each[prop]){
            if(prop!="coverPic"){
              return;
            } else {  // coverPic字段为空
              imgFlag = false;
              innerTpl = innerTpl.replace("<span class='", "<span class='long-title "); // 增加标题长度
            }
          }
          else if(prop=="sourcePublishTime"){ // 时间文字转换
            each.timeText = self.getTimeText(each[prop]);
          }
        }
        if(imgFlag && moduleName=="news") {
          innerTpl = innerTpl.replace("</a>", "<img src='{{coverPic}}'/></a>");  // 插入图片元素
        }
        contentHtml += UC.Template.format(innerTpl, each);// 提取具体数据
      });

      html = UC.Template.format(html, {
        moduleName: self.getModuleName(dataId),
        dataId: dataId,
        contentHtml: contentHtml
      });
      return html;
    },
    feedPage: function(html, dataId, $parent){
      $parent.find(".mid-content").hide();
      $parent.find("#J_"+dataId).remove();
      $parent.append(html);
      $("#J_" + dataId).fadeIn();
    },
    // 绑定错误页面刷新按钮
    bindErrorBtn: function() {
      var self = this;
      $(".error-refresh-btn").on("click", function(){
        var $content = $(this).closest(".mid-content");
        var module = $content.attr("mod");
        var dataId = $content.attr("data-id");
        self.showNextPage(module);
        UC.logWrite.write("click", {
          ck_rg: "Middle-"+self.logRgData[dataId]+"-ErrorPage",
          ck_name: "Refresh Button"
        });
      });
    },
    getModuleName: function(dataId) {
      return dataId.split("Content")[0];
    },
    // 加载页面时刷新
    loadingRefresh: function() {
      this.firstLoad = true;
      for(var each in this.firstScreenData) {
        var dataId = this.firstScreenData[each];
        // if(!this.isExpired(dataId)){
        //   this.renderPage(dataId);
        // } else {
          this.getData(dataId);
        // }
      }
    },
    // 换页，3个模块通用
    showNextPage: function(module){
      var dataId = this.getDataId(module);
      // var module = this.getModuleName(dataId);
      if(this.isExpired(dataId)){ // 过期的时候要拿新数据
        this.getData(dataId); // 获取新数据并填充页面
      } else {
        var number = this.moduleInfo[module].num;
        var $parent = $("." + module + "-wrapper");
        var allData = this.data[dataId];
        var start = this.pageId[dataId]? this.pageId[dataId] * number : 1; // 下一页数据起始index
        if (start + number > allData.length) {
          start = 0;
        }
        this.pageId[dataId] = start/number + 1;
        var data = allData? allData.slice(start, start+number):null;
        var pageHtml = this.getContentPage(data, this.moduleInfo[module].tpl, dataId);
        this.feedPage(pageHtml, dataId, $parent);
      }
    },
    // 切标签，news模块专用
    switchTab: function(dataId, $tab){
      $tab.siblings().removeClass("active");
      $tab.addClass("active");
      var self = this;
      self.currentTab = dataId;
      var $parent = $(".news-wrapper");
      var data;

      if (!self.data[dataId] || self.isExpired(dataId)) { // 从未成功加载过该标签页数据或该标签数据已过期时，发起请求
        self.getData(dataId);
      } else {
        $parent.find(".mid-content").hide();
        $("#J_" + dataId).fadeIn();
      }
    },
    isExpired: function(dataId){  // 判断是否过期，如果从来没有成功更新过也会判为过期
      // 暂时不做预渲染，无需从LS读取更新时间，此段代码暂时屏蔽
      /* var strUpdatedTime = ls && ls.item(this.lsTimeKey);
      try{
        var updatedTime = JSON.parse(strUpdatedTime);
      } catch(e) {
        var updatedTime = {};
      }
      var time = (updatedTime!=null)?updatedTime[dataId]:null; */
      var time = this.updatedTime[dataId];
      if(time){
        var currentTime = Date.parse(new Date());
        if(currentTime - time < 3600000) {
          return false;
        }
      }
      return true;
    },
    init: function(){
      var self = this;
      self.loadingRefresh();
      // Bind all behaviors
      // 切标签
      $(".news-tabs").find("li").on("click", function(){
        self.firstLoad = false;
        if ($(this).hasClass("active")) {
          return;
        }
        self.switchTab($(this).attr("data-id"), $(this));
        var logData = {
          ck_rg: "Middle-" + self.logRgData[$(this).attr("data-id")],
          ck_name: "Switch Tab"
        };
        UC.logWrite.write("click", logData);
      });
      // 换页
      $(".middle").find(".refresh-btn").on("click", function(){
        self.firstLoad = false;
        var moduleName = $(this).attr("mod");
        self.showNextPage(moduleName);
        var logData = {
          ck_rg: "Middle-" + self.logRgData[moduleName],
          ck_name: "Refresh Button"
        };
        UC.logWrite.write("click", logData);
      });
      // 点击打点
      $(".middle").on("click", "a", function(){
        var ckName = $(this).find(".ellipsis").text();
        var logData = {
          ck_rg: "Middle-" + self.logRgData[$(this).closest(".mid-content").attr("id").split("_")[1]],
          ck_po: $(this).index() + 1,
          ck_name: ckName
        };
        UC.logWrite.write("click", logData);
      });
    }
  };
  if(window.presetData) {
    UC.middleNewsVideo.init();
  }
})(UC || (UC = {}));
